import {pdfjs, Document, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc =
  window.location.origin + "/pdf.worker.min.mjs";
'use client';

export type FloatListStringTuple = [number[], string, string, string] | undefined;


import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import React, {useEffect, useState} from 'react';
import FileUploadBox from "./fileUpload";


type FileResult = {
    status: string;
    length: number;
    do_group: boolean;
    another_param: string;
};

type ResponseData = {
    files: Array<{ [filename: string]: FileResult }>;
    do_group: boolean;
    another_param: string;
};

const getRandomColorWithOpacity = () => {
    const r = Math.floor(Math.random() * 256); // Red: 0-255
    const g = Math.floor(Math.random() * 256); // Green: 0-255
    const b = Math.floor(Math.random() * 256); // Blue: 0-255
    return `rgba(${r}, ${g}, ${b}, 0.3)`; // 0.3 opacity
};

//bboxes is a list of [[page_bbox, category, color],]

function transformData(data: any): FloatListStringTuple[] {
    const result: FloatListStringTuple[] = [];

    if (data) {

        data.files.forEach((file: any) => {
            for (const fileName in file) {
                if (file[fileName].chunks) {
                    file[fileName].chunks.forEach((chunk: any) => {
                        result.push([chunk.bboxes, chunk.box_category, getRandomColorWithOpacity(), chunk.text]);
                    });
                }
            }
        });

        return result;
    }
    return []

}


export default function Sample() {
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState<ResponseData | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const data = transformData(responseData);

    const [pageNumber, setPageNumber] = useState(1);

    const nextPage = () => setPageNumber((prevPage) => prevPage + 1);
    const prevPage = () => setPageNumber((prevPage) => Math.max(prevPage - 1, 1));


    return (
        <div className="Example">
            <header>
                <h1>Takeoff DPP</h1>
            </header>
            <div>
                <button onClick={prevPage} disabled={pageNumber === 1}>Previous Page</button>
                <button onClick={nextPage} disabled={responseData == null}>Next Page</button>
            </div>

            <div className="Example__container">
                <FileUploadBox setResponseData={setResponseData} setLoading={setIsLoading} loading={isLoading}
                               setFile={setFile} file={file}></FileUploadBox>
                <div className="Example__container__document">
                    {!isLoading && file && responseData && (
                        <PdfViewer bboxes={data} pageNumber={pageNumber} fileBinary={file}/>
                    )}
                    {isLoading && <p>Loading</p>}
                </div>
            </div>
        </div>
    );
}

const renderRectangles = (bboxes_all: FloatListStringTuple[], pageNumber: number, frame_width: number, frame_height: number) => {
    const renderedRectangles = [];

    for (const [index, tuple] of bboxes_all.entries()) {
        if (tuple !== undefined) {
            const [chunk_bboxes, category, color, text] = tuple;
            const numRectangles = chunk_bboxes?.length / 5; // Calculate the number of rectangles
            for (let i = 0; i < numRectangles; i++) {
                const startIndex = i * 5; // Calculate the start index of the current tuple
                const [page, top_left_x, top_left_y,
                    bbox_width, bbox_height] = chunk_bboxes.slice(startIndex, startIndex + 5); // Extract the tuple

                if (page !== pageNumber) {
                    continue
                }

                const expansionFactorX = 1.02; // 10% expansion, you can adjust this value as needed
                const expansionFactorY = 1.05; // 10% expansion, you can adjust this value as needed
                const halfExpansionOffsetX = (expansionFactorX - 1) / 2;
                const halfExpansionOffsetY = (expansionFactorY - 1) / 2;

                console.log(top_left_x, top_left_y, bbox_width, bbox_height)

                if (top_left_y && top_left_x && bbox_height && bbox_width) {
                    // Create a div for each tuple representing a rectangle
                    renderedRectangles.push(
                        <div
                            key={index * 100 + i}
                            className="absolute rounded bg-opacity-30 border border-black border-opacity-50"
                            style={{
                                position: 'absolute',
                                top: ((top_left_y * frame_height) - (bbox_height * frame_height * halfExpansionOffsetY)),
                                left: ((top_left_x * frame_width) - (bbox_width * frame_width * halfExpansionOffsetX)),
                                width: bbox_width * frame_width * expansionFactorX,
                                height: bbox_height * frame_height * expansionFactorY,
                                borderRadius: '3px', // Rounded edges
                                backgroundColor: color, // Blue color with 30% opacity
                                border: '1px solid rgba(0, 0, 0, 0.3)', // 1pt solid black outline with 50% opacity
                            }}
                        ></div>
                    );
                }
            }
        }
    }
    return renderedRectangles;
}


interface PdfViewerProps {
    bboxes: FloatListStringTuple[];
    pageNumber: number;
    fileBinary: File;
}


const PdfViewer: React.FC<PdfViewerProps> = ({bboxes, pageNumber, fileBinary}) => {
    const [viewerWidth, setViewerWidth] = useState(0);
    const [viewerHeight, setViewerHeight] = useState(0);
    const scale = 1.5;
    const [blobUrl, setBlobUrl] = useState<string | null>(null);

    // Generate a Blob URL for the fileBinary
    useEffect(() => {
        if (fileBinary) {
            const url = URL.createObjectURL(fileBinary);
            setBlobUrl(url);

            // Clean up the Blob URL when component unmounts or fileBinary changes
            return () => URL.revokeObjectURL(url);
        }
    }, [fileBinary]);
    // Render PDF viewer when fileBinary is available
    return (
        <div className="pdf-viewer">
            {fileBinary && <Document
                file={blobUrl}
                loading={""}
            >
              <div style={{position: 'relative'}}>
                <Page
                    scale={scale}
                    pageNumber={pageNumber}
                    onLoadSuccess={async (page) => {
                        console.log(page.height)
                        console.log(page.width)
                        setViewerHeight(page.height)
                        setViewerWidth(page.width)
                    }}
                />

                  {viewerWidth && viewerHeight && renderRectangles(bboxes, pageNumber, viewerWidth, viewerHeight)}
              </div>
            </Document>}
        </div>
    );
}

