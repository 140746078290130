import React, {useState} from 'react';

type FileResult = {
    status: string;
    length: number;
    do_group: boolean;
    another_param: string;
};

type ResponseData = {
    files: Array<{ [filename: string]: FileResult }>;
    do_group: boolean;
    another_param: string;
} | null;

interface FileUploadBoxProps {
    setResponseData: React.Dispatch<React.SetStateAction<ResponseData>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    setFile: React.Dispatch<React.SetStateAction<File | null>>;
    file: File | null;
}



const FileUploadBox: React.FC<FileUploadBoxProps> = ({setResponseData, setLoading, loading, setFile, file}) => {
    const [doGroup, setDoGroup] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0] || null; // Get the first file or null if no file is selected
        setFile(selectedFile);
    };


    const handleUpload = async () => {
        if (!file) {
            alert("Please select a file.");
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("file", file); // Add the single file with a fixed name
        formData.append("do_group", doGroup ? "true" : "false");
        formData.append("normalize_coords", "true");
        const url = `${window.location.origin}/api/process?do_group=${doGroup ? "true" : "false"}&normalize_coords=true`;
        console.log("t1")
        try {
            const response = await fetch(url, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Failed to upload file");
            }

            const data: ResponseData = await response.json();
            setResponseData(data);
            console.log(data)
        } catch (error) {
            console.error("Error uploading file:", error);
            setResponseData(null); // Handle the error by resetting responseData
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h3>File Upload</h3>
            <input
                type="file"
                onChange={handleFileChange}
                disabled={loading}
            />
            <br/>
            <label>
                Do Group:
                <input
                    type="checkbox"
                    checked={doGroup}
                    onChange={(e) => setDoGroup(e.target.checked)}
                    disabled={loading}
                />
            </label>
            <br/>
            <button onClick={handleUpload} disabled={loading}>
                {loading ? "Uploading..." : "Upload"}
            </button>

        </div>
    );
}

export default FileUploadBox;
